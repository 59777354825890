<template>
  <div class="d-flex align-items-center mr-2">
    <b-avatar :variant="colorVariant" rounded>
      <feather-icon :icon="icon" size="18" />
    </b-avatar>
    <div class="ml-1">
      <h5 class="mb-0">
        {{ formattedTotalOutcome }}
      </h5>
      <small>{{ name }}</small>
    </div>
  </div>
</template>
<script>
import { BAvatar } from 'bootstrap-vue'
export default {
  components: {
    BAvatar
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'DollarSignIcon'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },

  data() {
    return {
      colorVariant: 'light-' + this.color
    }
  },
  computed: {
    formattedTotalOutcome() {
      return this.total.toLocaleString('en-Us', { style: 'currency', currency: 'EGP' })
    }
  }
}
</script>
