<template>
  <b-row class="address-container">
    <b-col>
      <address v-if="address">
        <p>{{ address.street }}</p>
        <p>{{ address.suite }}</p>
        <p>{{ address.governorate }}, {{ address.city }}, {{ address.postalCode }}</p>
        <p>{{ address.country }}</p>
      </address>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'CompanyAddress',
  components: {
    BRow,
    BCol
  },
  props: {
    address: {
      type: Object,
      required: true,
      default: () => ({
        street: '',
        suite: '',
        city: '',
        governorate: '',
        postalCode: '',
        country: ''
      })
    }
  }
}
</script>

<style scoped>
/* .address-container {
  padding: 1rem;
  border: 1px solid var(--border-color, #ced4da);
  border-radius: 0.25rem;
  background-color: var(--background-color, #fff);
  color: var(--text-color, #495057);
} */

.address-container address {
  font-style: italic;
}

.address-container p {
  margin: 0 0 0.5rem 0;
}
</style>
