<template>
  <b-table v-click-outside="handleClickOut" v-bind="{ ...$props, ...$attrs }" v-on="handleListeners($listeners)" :items="tableItems">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
      <div :key="index" v-if="showField(field, data, 'date')">
        <b-form-datepicker
          :id="`${field.key}-${data.item.id}`"
          @keydown.native="handleKeydown($event, index, data)"
          @input="(value) => inputHandler(value, data, field.key)"
          v-bind="{ ...field }"
          v-focus="enableFocus('date')"
          :key="index"
          :type="field.type"
          :value="getFieldValue(field, data)"
          :state="getValidity(data, field).valid ? null : false"
        ></b-form-datepicker>
        <b-tooltip
          v-if="getValidity(data, field).errorMessage"
          :target="`${field.key}-${data.item.id}`"
          variant="danger"
          :show="!getValidity(data, field).valid"
          :disabled="true"
        >
          {{ getValidity(data, field).errorMessage }}
        </b-tooltip>
      </div>
      <div :key="index" v-else-if="showField(field, data, 'select')">
        <b-form-select
          :id="`${field.key}-${data.item.id}`"
          @keydown.native="handleKeydown($event, index, data)"
          @change="(value) => inputHandler(value, data, field.key, field.options)"
          v-bind="{ ...field }"
          v-focus="enableFocus()"
          :value="getFieldValue(field, data)"
          :state="getValidity(data, field).valid ? null : false"
        ></b-form-select>
        <b-tooltip
          v-if="getValidity(data, field).errorMessage"
          :target="`${field.key}-${data.item.id}`"
          variant="danger"
          :show="!getValidity(data, field).valid"
          :disabled="true"
        >
          {{ getValidity(data, field).errorMessage }}
        </b-tooltip>
      </div>
      <div :key="index" v-else-if="showField(field, data, 'vue-select')">
        <v-select
        dir="rtl"
          v-model="tableMap[data.item.id].fields[field.key].value"
          :options="field.options"
          :reduce="comp => comp[field.selectId] || comp.value"
          :getOptionLabel="(comp) => comp[field.selectLabel]"
          @input="(value) => inputHandler(value, data, field.key, field.options, field.selectId)"
          @keydown.native="handleKeydown($event, index, data)"
          v-bind="{ ...field }"
          v-focus="enableFocus()"
          :state="getValidity(data, field).valid ? null : false"
        ></v-select>
        <span v-if="getValidity(data, field).errorMessage" style="color: red">{{ getValidity(data, field).errorMessage }}</span>
      </div>
      <b-form-checkbox
        :id="`${field.key}-${data.item.id}`"
        @keydown.native="handleKeydown($event, index, data)"
        @change="(value) => inputHandler(value, data, field.key)"
        v-bind="{ ...field }"
        v-focus="enableFocus('checkbox')"
        v-else-if="showField(field, data, 'checkbox')"
        :key="index"
        :checked="getFieldValue(field, data)"
      ></b-form-checkbox>
      <b-form-rating
        :id="`${field.key}-${data.item.id}`"
        @keydown.native="handleKeydown($event, index, data)"
        @change="(value) => inputHandler(value, data, field.key)"
        v-bind="{ ...field }"
        v-focus="enableFocus()"
        v-else-if="showField(field, data, 'rating')"
        :key="index"
        :value="getFieldValue(field, data)"
      ></b-form-rating>
      <div :key="index" v-else-if="showField(field, data, 'textarea')">
        <b-form-textarea
          :id="`${field.key}-${data.item.id}`"
          @keydown="handleKeydown($event, index, data)"
          @input="(value) => inputHandler(value, data, field.key)"
          @change="(value) => changeHandler(value, data, field.key)"
          v-bind="{ ...field }"
          v-focus="enableFocus()"
          :type="field.type"
          :value="getFieldValue(field, data)"
          :state="getValidity(data, field).valid ? null : false"
        ></b-form-textarea>
        <b-tooltip
          v-if="getValidity(data, field).errorMessage"
          :target="`${field.key}-${data.item.id}`"
          variant="danger"
          :show="!getValidity(data, field).valid"
          :disabled="true"
        >
          {{ getValidity(data, field).errorMessage }}
        </b-tooltip>
      </div>
      <div :key="index" v-else-if="showField(field, data, field.type)">
        <b-form-input
          :id="`${field.key}-${data.item.id}`"
          @keydown="handleKeydown($event, index, data)"
          @input="(value) => inputHandler(value, data, field.key)"
          @change="(value) => changeHandler(value, data, field.key)"
          v-bind="{ ...field }"
          v-focus="enableFocus()"
          :type="field.type"
          :value="getFieldValue(field, data)"
          :state="getValidity(data, field).valid ? null : false"
        ></b-form-input>
        <!-- <b-form-invalid-feedback v-if="getValidity(data, field).errorMessage">{{ getValidity(data, field).errorMessage }}</b-form-invalid-feedback> -->

        <!-- <b-tooltip
          v-if="getValidity(data, field).errorMessage"
          :target="`${field.key}-${data.item.id}`"
          variant="danger"
          :show="!getValidity(data, field).valid"
          :disabled="true"
        >
          {{ getValidity(data, field).errorMessage }}
        </b-tooltip> -->
      </div>
      <div class="data-cell" @[editTrigger]="handleEditCell($event, data.item.id, field.key, field.editable)" v-else :key="index">
        <slot v-if="$scopedSlots[`cell(${field.key})`]" :name="`cell(${field.key})`" v-bind="getCellData(data)"></slot>
        <template v-else>{{ getCellValue(data, field) }}</template>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BFormDatepicker, BFormInput, BFormSelect, BFormCheckbox, BFormRating, BTooltip, BFormInvalidFeedback } from 'bootstrap-vue'
// import { validate } from 'uuid'
import Vue from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  name: 'BEditableTable',
  components: {
    vSelect,
    BTable,
    BFormDatepicker,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormRating,
    BTooltip,
    BFormInvalidFeedback
  },
  props: {
    fields: Array,
    items: Array,
    value: Array,
    editMode: {
      type: String,
      default: 'cell'
    },
    editTrigger: {
      type: String,
      default: 'click'
    },
    rowUpdate: {
      type: Object,
      default: null
    },
    disableDefaultEdit: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    focus: {
      inserted(el, event) {
        switch (event.value) {
          case false: {
            return
          }
          case 'checkbox':
            el.children[0].focus()
          case 'date':
            el.children[0].focus()
          default:
            el.focus()
        }
      }
    },
    clickOutside: {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            if (document.contains(event.target)) {
              vnode.context[binding.expression](event)
            }
          }
        }
        document.addEventListener('click', el.clickOutsideEvent)
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  data() {
    return {
      selectedValue: null,
      selectedCell: null,
      tableItems: [],
      tableMap: {},
      localChanges: {}
    }
  },
  mounted() {
    this.editMode = this.editMode
    this.createTableItems(this.value ? this.value : this.items)
  },
  watch: {
    value(newVal) {
      this.createTableItems(newVal)
    },
    items(newVal) {
      this.createTableItems(newVal)
    },
    rowUpdate: {
      handler(newVal) {
        if (this.tableMap[newVal.id]) {
          this.tableMap[newVal.id].isEdit = newVal.edit
        }
        if (newVal.action === 'update') {
          // if there is validation error, do not update the data and keep the edit mode
          if (!this.checkValidation(newVal.id)) {
            this.tableMap[newVal.id].isEdit = true
            return
          }
          this.clearValidation(newVal.id)
          this.updateData(newVal.id)
        } else if (newVal.action === 'add') {
          this.updateData(newVal.id, 'add', { ...newVal.data }, newVal.edit, newVal.addPosition)
        } else if (newVal.action === 'delete') {
          this.updateData(newVal.id, 'delete')
        } else if (newVal.action === 'cancel' || newVal.isEdit === false) {
          this.clearValidation(newVal.id)
          delete this.localChanges[newVal.id]
        }
      },
      deep: true
    }
  },
  methods: {
    handleEditCell(e, id, name, editable) {
      if (!this.disableDefaultEdit && editable) {
        e.stopPropagation()
        this.clearEditMode()
        this.updateData()
        this.tableMap[id].isEdit = true
        this.selectedCell = name
        this.clearValidation(id)
        if (!this.localChanges[id]) {
          this.localChanges[id] = {}
        }
      }
    },
    clearValidation(id) {
      for (const key in this.tableMap[id].fields) {
        this.tableMap[id].fields[key].validity = { valid: true }
      }
    },
    checkValidation(id) {
      //check validation on all fields by calling it
      for (const key in this.tableMap[id].fields) {
        if (!this.tableMap[id].fields[key].validity.valid) {
          return false
        }
      }
      return true
    },
    handleKeydown(e, index, data) {
      if ((e.code === 'Tab' || e.code === 'Enter') && this.editMode === 'cell' && !this.disableDefaultEdit) {
        e.preventDefault()
        let fieldIndex = this.fields.length - 1 === index ? 0 : index + 1
        let rowIndex = this.fields.length - 1 === index ? this.tableMap[data.item.id].rowIndex + 1 : this.tableMap[data.item.id].rowIndex
        let i = fieldIndex
        while (!this.fields[i].editable) {
          if (i === this.fields.length - 1) {
            i = 0
            rowIndex++
          } else {
            i++
          }
        }
        fieldIndex = i
        this.selectedCell = this.fields[fieldIndex].key
        this.clearEditMode(data.item.id)
        this.updateData(data.item.id)

        const rowId = this.tableItems[rowIndex]?.id
        if (this.tableMap[rowId]) {
          this.tableMap[rowId].isEdit = true
          if (!this.localChanges[rowId]) {
            this.localChanges[rowId] = {}
          }
        }
      } else if (e.code === 'Escape') {
        e.preventDefault()
        this.selectedCell = null
        this.clearEditMode(data.item.id)
        this.localChanges = {}
      }
    },
    handleClickOut() {
      if (!this.disableDefaultEdit) {
        this.selectedCell = null
        this.clearEditMode()
        this.updateData()
      }
    },
    inputHandler(value, data, key, options, selectId) {
      let changedValue = value
      if (options && value != null) {
        if (selectId) {
          const selectedValue = options.find((option) => option[selectId] === value[selectId])
          changedValue = selectedValue ? selectedValue[selectId] : value
        } else {
          const selectedValue = options.find((item) => item.value === value)
          changedValue = selectedValue ? selectedValue.value : value
        }
      }
      const validity = data.field.validate ? data.field.validate(changedValue, data) : { valid: true }
      const fields = this.tableMap[data.item.id].fields
      fields[key].validity.valid = true

      if (this.value && (!validity || validity?.valid === true)) {
        if (!this.localChanges[data.item.id]) {
          this.localChanges[data.item.id] = {}
        }
        this.localChanges[data.item.id][key] = {
          value: changedValue,
          rowIndex: this.tableMap[data.item.id]?.rowIndex
        }
      } else {
        fields[key].validity = validity
      }
      const fieldType = data.field.type
      const excludeTypes = {
        text: true,
        range: true,
        number: true
      }
      if (!excludeTypes[fieldType]) {
        this.$emit('input-change', {
          ...data,
          id: data.item.id,
          value: changedValue,
          validity: { ...fields[key].validity }
        })
      }
    },
    changeHandler(value, data, key) {
      this.$emit('input-change', {
        ...data,
        id: data.item.id,
        value,
        validity: { ...this.tableMap[data.item.id].fields[key].validity }
      })
    },
    updateData(id, action, data, isEdit, addPosition) {
      let isUpdate = false
      const objId = id ? id : Object.keys(this.localChanges)[0]
      if (action === 'add') {
        isUpdate = true
        this.tableMap[id] = { id, isEdit, fields: {} }
        if (addPosition === 'end') {
          this.tableItems.push(data)
        } else {
          this.tableItems.unshift(data)
        }
      } else if (action === 'delete') {
        isUpdate = true
        delete this.tableMap[id]
        this.tableItems = this.tableItems.filter((item) => item.id !== id)
      } else {
        const objValue = id ? this.localChanges[id] : Object.values(this.localChanges)[0]
        if (this.value && objValue) {
          Object.keys(objValue).forEach((key) => {
            isUpdate = true
            const cell = objValue[key]
            this.tableMap[objId].fields[key].value = cell.value
            let rowIndex = cell.rowIndex
            const currentPage = this.$attrs['current-page']
            const perPage = this.$attrs['per-page']
            if (currentPage > 1 && perPage > 0) {
              rowIndex = (currentPage - 1) * perPage + rowIndex
            }
            this.tableItems[rowIndex][key] = cell.value
          })
        }
      }
      if (isUpdate) {
        this.$emit('input', this.tableItems)
      }
      delete this.localChanges[id ? id : objId]
    },
    handleListeners(listeners) {
      const excludeEvents = {
        input: true,
        'input-change': true
      }
      return Object.keys(listeners).reduce((a, c) => (excludeEvents[c] ? a : { ...a, [c]: listeners[c] }), {})
    },
    getCellValue(data, field) {
      const row = this.tableMap[data.item.id]
      let value = row && row.fields[field.key] ? row.fields[field.key].value : ''
      if (data.field.options) {
        if (field.selectLabel) {
          const selectedValue = data.field.options.find((option) => option[field.selectId] === value)
          value = selectedValue ? selectedValue[field.selectLabel] : value
        } else {
          const selectedValue = data.field.options.find((item) => item.value === value)
          value = selectedValue ? selectedValue.text : value
        }
      }
      return value
    },
    getCellData(data) {
      return {
        ...data,
        isEdit: this.tableMap[data.item.id].isEdit,
        id: data.item.id
      }
    },
    getValidity(data, field) {
      return this.tableMap[data.item.id].fields[field.key].validity
    },
    showField(field, data, type) {
      return field.type === type && this.tableMap[data.item.id]?.isEdit && (this.selectedCell === field.key || this.editMode === 'row') && field.editable
    },
    getFieldValue(field, data) {
      return this.tableMap[data.item.id].fields[field.key]?.value
    },
    getFieldValue2(field, data) {
      return field.options.find((option) => option[field.selectId] === data.item[field.selectId])
    },
    enableFocus(type) {
      return this.editMode === 'cell' ? type : false
    },
    clearEditMode(id) {
      if (id) {
        this.tableMap[id].isEdit = false
      } else {
        for (const changeId in this.localChanges) {
          if (this.tableMap[changeId]) {
            this.tableMap[changeId].isEdit = false
          }
        }
      }
    },
    createTableItems(data) {
      this.tableItems = data.map((item) => ({ ...item }))
      this.tableMap = data.reduce(
        (rows, curRow, rowIndex) => ({
          ...rows,
          [curRow.id]: {
            id: curRow.id,
            isEdit: this.tableMap[curRow.id] ? this.tableMap[curRow.id].isEdit : false,
            rowIndex,
            fields: Object.keys(curRow).reduce(
              (keys, curKey) => ({
                ...keys,
                [curKey]: {
                  value: curRow[curKey],
                  validity: this.tableMap[curRow.id]?.fields[curKey]?.validity ? this.tableMap[curRow.id].fields[curKey].validity : { valid: true }
                }
              }),
              {}
            )
          }
        }),
        {}
      )
    }
  }
})
</script>

<style scoped>
.data-cell {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
