<template>
  <b-card>
    <div class="table-container">
      <h2 class="mb-2 text-center">
        <span v-if="type == 1"> اشعار رقم </span>
        <span v-else>فاتورة بدون ضريبة رقم </span>
        <b-badge variant="primary">{{ invoiceNumber }}</b-badge>
      </h2>
      <!-- <span style="margin-top: -70px; float: right" class="btn btn-primary">اشعار قم {{ invoiceNumber }}</span> -->

      <b-skeleton-table v-if="!optionsFetched" :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <BEditableTable
        v-else="optionsFetched"
        disableDefaultEdit
        :rowUpdate="rowUpdate"
        :editMode="'row'"
        class="editable-table"
        v-model="localNoticeItems"
        :fields="[...fields]"
        :sortBy="'sortOrder'"
        :sortDesc="false"
        borderless
        responsive
      >
        <!-- index -->
        <template #cell(index)="data">
          <div>{{ data.index + 1 }}</div>
        </template>

        <!-- price -->
        <template #cell(price)="data">
          <div>{{ formatCurrency(data.item.price) }}</div>
        </template>

        <template #custom-foot="data">
          <b-tr v-if="!isReview">
            <b-td class="d-flex align-items-center plus-icon rounded-circle"> <BIconPlus variant="success" @click="handleAdd()"></BIconPlus></b-td>
            <b-td colspan="2"></b-td>
          </b-tr>
          <b-tr>
            <!-- <b-td class="d-flex align-items-center plus-icon rounded-circle"> <BIconPlus variant="success" @click="handleAdd()"></BIconPlus></b-td> -->
            <b-td colspan="3"></b-td>

            <b-td style="font-size: 1.3rem">
              <div class="font-weight-bold"><span style="font-size: 1.5rem">المجموع الكلي </span> {{ formatCurrency(alltotal) }}</div>
            </b-td>
            <b-td></b-td>
          </b-tr>
        </template>

        <template #cell(edit)="data">
          <div v-if="!isReview" class="text-center">
            <div v-if="data.isEdit">
              <BIconX class="edit-icon" @click="handleSubmit(data, false)"></BIconX>
              <BIconCheck class="edit-icon" @click="handleSubmit(data, true)"></BIconCheck>
            </div>
            <div v-else>
              <b-button class="rounded-circle p-1 mr-1" variant="outline-primary" @click="handleEdit(data, true)">
                <BIconPencil></BIconPencil>
              </b-button>
              <b-button class="rounded-circle p-1 mr-1" variant="outline-danger" @click="handleDelete(data)">
                <BIconTrash></BIconTrash>
              </b-button>
              <BIconArrowUp v-if="data.index !== 0" class="edit-icon" @click="moveUp(data.index)"></BIconArrowUp>
              <BIconArrowDown v-if="data.index !== localNoticeItems.length - 1" class="edit-icon" @click="moveDown(data.index)"></BIconArrowDown>
            </div>
          </div>
        </template>
      </BEditableTable>
    </div>
  </b-card>
</template>

<script>
import BEditableTable from './EditGrid.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BIconArrowUp,
  BIconArrowDown,
  BIconPlusCircle,
  BIconPlus,
  BTh,
  BTr,
  BTd,
  BSkeletonTable,
  BCard,
  BBadge
} from 'bootstrap-vue'

export default {
  components: {
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BIconArrowUp,
    BIconArrowDown,
    BIconPlus,
    BTh,
    BTr,
    BTd,
    BCardCode,
    BSkeletonTable,
    BCard,
    BBadge
  },
  data() {
    return {
      optionsFetched: false,
      alltotal: 0,
      fields: [
        {
          key: 'index',
          label: '#',
          thStyle: 'font-size: 1.5rem; width: 60px;',
          isRowHeader: true
        },
        {
          key: 'productId',
          label: 'الاسم',
          type: 'vue-select',
          selectLabel: 'name',
          selectId: 'id',
          thStyle: 'font-size: 1.5rem; width: 200px;',
          editable: true,
          validate: this.validateProduct,
          placeholder: 'ادخل المنتج'
        },
        {
          key: 'description',
          label: 'الوصف',
          thStyle: 'font-size: 1.5rem; width: 200px;',
          type: 'text',
          editable: true,
          placeholder: 'ادخل الوصف'
        },
        {
          key: 'price',
          label: 'السعر',
          thStyle: 'font-size: 1.5rem; width: 100px;',
          type: 'number',
          editable: true,
          placeholder: 'ادخل السعر',
          validate: this.validatePrice
        },
        { key: 'edit', label: '', thStyle: 'width: 200px;' }
      ],
      rowUpdate: {},
      localNoticeItems: []
    }
  },
  props: {
    noticeItems: {
      type: Array
    },
    invoiceNumber: {
      type: String,
      required: false,
      default: '0000'
    },
    isReview: {
      type: Boolean
    },
    type: {
      type: Number
    }
  },

  computed: {
    ...mapGetters('products', ['getProducts']),
    // isEditRow indicate if the row in edit mode
    isEditRow() {
      return this.localNoticeItems.some((item) => item.id === this.rowUpdate.id)
    }
  },
  async mounted() {
    // set localNoticeItems to the noticeItems prop and add prop id to each item to be used in the editable table
    this.localNoticeItems = this.noticeItems.map((item, index) => ({ ...item, id: index }))

    this.fields.find((field) => field.key === 'productId').options = this.getProducts
    this.$nextTick(() => {
      this.calcAllTotal()
    })
    this.optionsFetched = true
  },
  watch: {
    localNoticeItems: {
      handler(newVal) {
        newVal.forEach((item) => {
          item.price = parseFloat(item.price)
        })
        this.calcAllTotal()
        this.setNoticeItems(newVal)
      },
      deep: true
    }
  },
  methods: {
    setNoticeItems(newItems) {
      this.$emit('update-notice-items', newItems)
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EGP' }).format(value)
    },

    calcAllTotal() {
      this.alltotal = 0
      this.localNoticeItems.forEach((item) => {
        this.alltotal += item.price
      })
      this.$emit('get-total', { noticeTotal: this.alltotal })
    },

    moveUp(index) {
      if (index === 0) return
      ;[this.localNoticeItems[index].sortOrder, this.localNoticeItems[index - 1].sortOrder] = [
        this.localNoticeItems[index - 1].sortOrder,
        this.localNoticeItems[index].sortOrder
      ]

      this.localNoticeItems.splice(index - 1, 0, this.localNoticeItems.splice(index, 1)[0])
    },

    moveDown(index) {
      if (index === this.localNoticeItems.length - 1) return
      ;[this.localNoticeItems[index].sortOrder, this.localNoticeItems[index + 1].sortOrder] = [
        this.localNoticeItems[index + 1].sortOrder,
        this.localNoticeItems[index].sortOrder
      ]

      this.localNoticeItems.splice(index + 1, 0, this.localNoticeItems.splice(index, 1)[0])
    },

    handleAdd() {
      // do not add if there is an empty row or an edit row in rowUpdate make toaster to notify the user
      if (this.localNoticeItems.some((item) => item.id === undefined) || this.rowUpdate.edit) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'لا يمكن اضافة صف جديد',
            text: 'يجب اكمال الصفوف الفارغة او حفظ التعديلات الحالية',
            icon: 'XCircleIcon',
            variant: 'danger'
          }
        })
        return
      }

      const newId = Date.now()
      this.rowUpdate = {
        edit: true,
        id: newId,
        action: 'add',
        addPosition: 'end',
        // The default add position is at the top of the list. Use the below prop to insert a new row to the very end.
        data: {
          id: newId,
          productId: this.getProducts[0].id,
          price: 1,
          description: '',
          sortOrder: this.localNoticeItems.length + 1
        }
      }
    },
    handleSubmit(data, update) {
      this.rowUpdate = {
        edit: false,
        id: data.id,
        action: update ? 'update' : 'cancel'
      }
    },
    handleEdit(data) {
      this.rowUpdate = { edit: true, id: data.id }
    },
    handleDelete(data) {
      this.rowUpdate = { id: data.id, action: 'delete' }
      //update sort order for all items after the deleted item
      this.localNoticeItems.forEach((item) => {
        if (item.sortOrder > data.sortOrder) {
          item.sortOrder--
        }
      })
    },
    validateProduct(value) {
      if (value === '' || value === null) {
        return {
          valid: false,
          errorMessage: 'المنتج مطلوب'
        }
      }
      return { valid: true }
    },
    validatePrice(value) {
      if (value === '' || value == '0' || value === null) {
        return {
          valid: false,
          errorMessage: 'السعر مطلوب'
        }
      }
      return { valid: true }
    }
  }
}
</script>

<style>
.table-container {
  margin: 10px;
}
[dir] .table {
  margin-bottom: 0rem;
}
[dir] .table th,
[dir] .table td {
  padding: 0.5rem;
}

table.editable-table {
  margin-top: 10px;
}

table.editable-table td {
  vertical-align: middle;
}
[dir] .table th,
[dir] .table td {
  text-align: right;
  padding: 0.5rem;
}

.editable-table .custom-checkbox {
  width: 50px;
}

.remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.edit-icon {
  color: rgb(4, 83, 158);
  cursor: pointer;
  font-size: 2rem;
}
.plus-icon {
  cursor: pointer;
  font-size: 3rem;
}

.name-col {
  width: 200px;
}

.department-col {
  width: 150px;
}
.age-col {
  width: 100px;
}

.date-col {
  width: 200px;
}

.total-col {
  text-align: center;
}

.reorder-col {
  width: 60px;
}

.is-active-col {
  width: 100px;
}
</style>
