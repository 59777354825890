var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-5"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"md":"8"}},[_c('h2',{staticClass:"mb-2 text-center"},[_vm._v(" ملحق فاتورة رقم "),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.invoiceNumber))])],1),_c('div',{staticClass:"form-row form-group"},[_c('div',{staticClass:"col-sm-4 col-lg-6 margin-md"},[_c('ValidationProvider',{attrs:{"name":"الاسم","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fees-name-input","type":"text","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.feesName),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "feesName", $$v)},expression:"supplementInvoice.feesName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm col-lg-2"},[_c('ValidationProvider',{attrs:{"name":"القيمة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fees-price","type":"number","step":"0.00001","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.feesPrice),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "feesPrice", $$v)},expression:"supplementInvoice.feesPrice"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-row form-group"},[_c('div',{staticClass:"col-sm-4 col-lg-6 margin-md"},[_c('ValidationProvider',{attrs:{"name":"الاسم","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"auto-input","type":"text","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.automatedServicesName),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "automatedServicesName", $$v)},expression:"supplementInvoice.automatedServicesName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm col-lg-2"},[_c('ValidationProvider',{attrs:{"name":"القيمة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fees-price","type":"number","step":"0.00001","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.preAutomatedServices),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "preAutomatedServices", $$v)},expression:"supplementInvoice.preAutomatedServices"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"1.5rem","margin-top":"5px"}},[_vm._v("القيمة الفعلية : "+_vm._s(_vm.totalAutomatedServices))])]),_c('div',{staticClass:"form-row form-group"},[_c('div',{staticClass:"col-sm-4 col-lg-6 margin-md"},[_c('ValidationProvider',{attrs:{"name":"الاسم","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tax-name-input","type":"text","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.taxName),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "taxName", $$v)},expression:"supplementInvoice.taxName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm col-lg-2"},[_c('ValidationProvider',{attrs:{"name":"القيمة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fees-price","type":"number","step":"0.00001","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.taxPrice),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "taxPrice", $$v)},expression:"supplementInvoice.taxPrice"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"form-row form-group"},[_c('div',{staticClass:"col-sm-4 col-lg-6 margin-md"},[_c('ValidationProvider',{attrs:{"name":"الاسم","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"com-name-input","type":"text","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.commercialIndustrialProfitsName),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "commercialIndustrialProfitsName", $$v)},expression:"supplementInvoice.commercialIndustrialProfitsName"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-sm col-lg-2"},[_c('ValidationProvider',{attrs:{"name":"القيمة","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fees-price","type":"number","step":"0.00001","state":errors.length === 0 ? null : false,"placeholder":"ادخل القيمة","disabled":_vm.isReview},model:{value:(_vm.supplementInvoice.commercialIndustrialProfits),callback:function ($$v) {_vm.$set(_vm.supplementInvoice, "commercialIndustrialProfits", $$v)},expression:"supplementInvoice.commercialIndustrialProfits"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }