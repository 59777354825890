<template>
  <b-card class="mb-5">
    <b-row align-h="center">
      <b-col md="8">
        <h2 class="mb-2 text-center">
          ملحق فاتورة رقم <b-badge variant="primary">{{ invoiceNumber }}</b-badge>
        </h2>
        <div class="form-row form-group">
          <div class="col-sm-4 col-lg-6 margin-md">
            <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
              <b-form-input
                id="fees-name-input"
                type="text"
                v-model="supplementInvoice.feesName"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div class="col-sm col-lg-2">
            <ValidationProvider name="القيمة" rules="required" v-slot="{ errors }">
              <b-form-input
                id="fees-price"
                type="number"
                step="0.00001"
                v-model="supplementInvoice.feesPrice"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row form-group">
          <div class="col-sm-4 col-lg-6 margin-md">
            <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
              <b-form-input
                id="auto-input"
                type="text"
                v-model="supplementInvoice.automatedServicesName"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div class="col-sm col-lg-2">
            <ValidationProvider name="القيمة" rules="required" v-slot="{ errors }">
              <b-form-input
                id="fees-price"
                type="number"
                step="0.00001"
                v-model="supplementInvoice.preAutomatedServices"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div style="font-size: 1.5rem; margin-top: 5px" class="font-weight-bold">القيمة الفعلية : {{ totalAutomatedServices }}</div>
        </div>
        <div class="form-row form-group">
          <div class="col-sm-4 col-lg-6 margin-md">
            <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
              <b-form-input
                id="tax-name-input"
                type="text"
                v-model="supplementInvoice.taxName"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div class="col-sm col-lg-2">
            <ValidationProvider name="القيمة" rules="required" v-slot="{ errors }">
              <b-form-input
                id="fees-price"
                type="number"
                step="0.00001"
                v-model="supplementInvoice.taxPrice"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row form-group">
          <div class="col-sm-4 col-lg-6 margin-md">
            <ValidationProvider name="الاسم" rules="required" v-slot="{ errors }">
              <b-form-input
                id="com-name-input"
                type="text"
                v-model="supplementInvoice.commercialIndustrialProfitsName"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
          <div class="col-sm col-lg-2">
            <ValidationProvider name="القيمة" rules="required" v-slot="{ errors }">
              <b-form-input
                id="fees-price"
                type="number"
                step="0.00001"
                v-model="supplementInvoice.commercialIndustrialProfits"
                :state="errors.length === 0 ? null : false"
                placeholder="ادخل القيمة"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>
        <!-- <div>
          <b-form-group label-cols-sm="4" label-cols-lg="6" content-cols-sm content-cols-lg="4" label="خدمات مميكنة ونوباتجية ودمغات وفحص اشعة">
            <ValidationProvider name="قيمة الخدمات" rules="required" v-slot="{ errors }">
              <b-form-input
                type="number"
                step="0.00001"
                v-model="supplementInvoice.automatedServices"
                :disabled="isReview"
                :state="errors.length === 0 ? null : false"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div>
          <b-form-group label-cols-sm="4" label-cols-lg="6" content-cols-sm content-cols-lg="4" label="ضريبة قيمة مضافة 14%" label-for="input-horizontal">
            <ValidationProvider name="قيمة الضريبة" rules="required" v-slot="{ errors }">
              <b-form-input
                type="number"
                step="0.00001"
                v-model="supplementInvoice.taxPrice"
                :state="errors.length === 0 ? null : false"
                :disabled="isReview"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </div>
        <div>
          <b-form-group label-cols-sm="4" label-cols-lg="6" content-cols-sm content-cols-lg="4" label="ارباح تجارية وصناعية" label-for="input-horizontal">
            <ValidationProvider name="قيمة ارباح تجارية" rules="required" v-slot="{ errors }">
              <b-form-input
                type="number"
                step="0.00001"
                :disabled="isReview"
                v-model="supplementInvoice.commercialIndustrialProfits"
                :state="errors.length === 0 ? null : false"
              ></b-form-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </div> -->
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BFormGroup, BFormInput, BCard, BRow, BCol, BFormInvalidFeedback, BBadge } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { extend } from 'vee-validate'
import { required, min, min_value, numeric, double } from '@validations'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    BFormInvalidFeedback,
    BBadge
  },
  props: {
    initialSupplementInvoice: Object,
    invoiceNumber: String,
    isReview: Boolean
  },
  data() {
    return {
      supplementInvoice: this.initialSupplementInvoice || {
        feesName: 'رسوم جمركية - البند 2%',
        automatedServicesName: 'خدمات مميكنة ونوباتجية ودمغات وفحص اشعة',
        taxName: 'ضريبة قيمة مضافة 14%',
        commercialIndustrialProfitsName: 'ارباح تجارية وصناعية',
        feesPrice: 0,
        preAutomatedServices: 0,
        automatedServices: 0,
        taxPrice: 0,
        commercialIndustrialProfits: 0,
        total: 0
      }
    }
  },
  computed: {
    //preAutomatedServices
    totalAutomatedServices() {
      return (
        Number(this.supplementInvoice.preAutomatedServices) -
        (Number(this.supplementInvoice.feesPrice) + Number(this.supplementInvoice.taxPrice) + Number(this.supplementInvoice.commercialIndustrialProfits))
      )
    }
  },
  watch: {
    //preAutomatedServices
    supplementInvoice: {
      handler(newVal) {
        this.$emit('update-supplement', newVal)
      },
      deep: true
    },
    initialSupplementInvoice: {
      immediate: true,
      handler(newVal) {
        this.supplementInvoice = newVal || {
          feesName: 'رسوم جمركية - البند 2%',
          automatedServicesName: 'خدمات مميكنة ونوباتجية ودمغات وفحص اشعة',
          taxName: 'ضريبة قيمة مضافة 14%',
          commercialIndustrialProfitsName: 'ارباح تجارية وصناعية',
          feesPrice: 0,
          preAutomatedServices: 0,
          automatedServices: 0,
          taxPrice: 0,
          commercialIndustrialProfits: 0,
          total: 0
        }
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 768px) {
  .margin-md {
    margin-bottom: 1.5rem;
  }
}
/* Add your custom styles here */
</style>
